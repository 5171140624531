<template>
    <div class="page" v-title data-title="控制面板">
        <br>
        <div>
            <div v-on:click="entryRecord" class="yx_l_chart">
                <i class="iconfont icon-xiangqing" style="font-size: 28px; color: #0066FF;"></i>
            </div>
            <div class="yj_chart">
                <span style="color: #0066FF;">{{ statusLabel }}</span>
            </div>
            <div v-on:click="entryDevice" class="yx_r_chart">
                <i class="iconfont icon-shezhi" style="font-size: 28px; color: #0066FF;"></i>
            </div>
        </div>
        <br><br><br>
        <div v-if="(zy_open || wh_open) && oxyConcentration" class="yx-chart">
            <span style="font-size: 82px; line-height: 200px;">{{ oxyConcentration }}</span>
            <span style="font-size: 28px; line-height: 160px; float: right; position: absolute;">%</span>
        </div>
        <div v-else class="yx-chart">
            <span style="font-size: 82px; line-height: 220px;">--</span>
            <span style="font-size: 28px; line-height: 200px; float: right; position: absolute;">%</span>
        </div>
        <div style="position: absolute; width: 100%; color: #FFFFFF; margin-top: -22%; text-align: center">
            <span style="font-size: 20px;">氧浓度显示</span>
        </div>
        <br>
        <div class="loading_demo" style="text-align: center;" v-bind:style="{'visibility':loading ? 'visible':'hidden'}">
            <i class="weui-loading" style="width: 30px; height: 30px;"></i>
            <span class="weui-primary-loading">
                <span class="weui-primary-loading__dot"></span>
            </span>
        </div>
        <br>
        <div style="margin-left: 8%">
            <ul>
                <li style="color: #0066FF;"><span style="font-size: 32px;">{{ oxyBlood }}</span>%</li>
                <li style="margin-left: -8%">血氧饱和度</li>
            </ul>
            <span class="y-line"></span>
            <ul>
                <li style="color: #0066FF;"><span style="font-size: 32px;">{{ cumulativeTime }}</span>小时</li>
                <li>累计时间</li>
            </ul>
            <span class="y-line"></span>
            <ul>
                <li style="color: #0066FF;"><span style="font-size: 32px; padding-left: 10px">{{ flow }}</span>L/min</li>
                <li>流量大小</li>
            </ul>
        </div>
        <br>
        <div class="weui-tabbar tab-bottom">
            <a v-on:click="downstream(1)" class="weui-tabbar__item" v-bind:class="{'weui-bar__item_on':zy_open}">
                <i class="iconfont icon-yangqi weui-tabbar__icon"></i>
                <p class="weui-tabbar__label">制氧</p>
            </a>
            <a v-on:click="downstream(2)" class="weui-tabbar__item" v-bind:class="{'weui-bar__item_on':wh_open}">
                <i class="iconfont icon-wumai weui-tabbar__icon"></i>
                <p class="weui-tabbar__label">雾化</p>
            </a>
            <a v-on:click="dsControl" class="weui-tabbar__item" v-bind:class="{'weui-bar__item_on':ds_open}">
                <i class="iconfont icon-dingshi weui-tabbar__icon"></i>
                <p class="weui-tabbar__label">{{ dsLabel }}</p>
            </a>
            <a v-on:click="entryStatistics" class="weui-tabbar__item">
                <i class="iconfont icon-tongji weui-tabbar__icon"></i>
                <p class="weui-tabbar__label">统计</p>
            </a>
        </div>
        <div id="dialogs">
            <div class="js_dialog">
                <div class="weui-mask" v-show="mask_open"></div>
                <div id="js_dialog_1" class="weui-half-screen-dialog" v-show="dialog_open">
                    <div class="weui-half-screen-dialog__hd">
                        <div class="weui-half-screen-dialog__hd__side">
                            <!--<button v-on:click="closeDialog" class="weui-icon-btn">关闭<i class="weui-icon-close-thin"></i></button>-->
                        </div>
                        <div class="weui-half-screen-dialog__hd__main">
                            <span class="weui-half-screen-dialog__title">定时</span>
                        </div>
                    </div>
                    <div style="width: 100%; margin-bottom: 12%; border-top: 1px solid rgba(0, 0, 0, 0.1)"></div>
                    <div class="weui-half-screen-dialog__bd">
                        <div style="font-size: 50px; text-align: center">
                            <i v-on:click="dsOperator(false)" class="iconfont icon-jian" style="float: left; margin-left: 12%; font-size: 40px"></i>
                            <span style=""> {{ ds_time }}</span>
                            <span style="font-size: 16px;">分钟</span>
                            <i v-on:click="dsOperator(true)" class="iconfont icon-jia" style="float: right; margin-right: 12%; font-size: 40px"></i>
                        </div>
                        <div style="width: 100%; margin-top: 12%; border-top: 1px solid rgba(0, 0, 0, 0.1)"></div>
                        <div style="height: 3em; text-align: center">
                            <div v-on:click="closeDialog" style="float: left; width: 50%; line-height: 3em">取消</div>
                            <div v-on:click="downstream(3)" style="float: right; width: 50%; line-height: 3em">确认</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import websocket from "@/utils/websocket";
    import http from "@/utils/http";

    export default {
        name: "Awgels_Panel",
        data() {
          return {
              zy_open: false,
              wh_open: false,
              ds_open: false,
              mask_open: false,
              dialog_open: false,
              ds_time: 10,
              dsLabel: '定时',
              statusLabel: '待机中...',
              oxyConcentration: '',
              oxyBlood: '--',
              flow: '0',
              cumulativeTime: '0',
              deviceNo: this.$route.params.deviceNo,
              times: 20, // 指令超时时间
              eventKey: 0, // 1-制氧, 2-雾化, 3-定时, 4-心跳
              eventVal: false, // false-关, true-开
              loading: false
          }
        },
        created() {
            websocket.connect(
                process.env.VUE_APP_BASE_URL + '/ws',
                '/topic/data/' + this.deviceNo,
                res => {
                    showResponse(res, this);
                },
                '/topic/ctl/' + this.deviceNo,
                res => {
                    showCtlResponse(res, this);
                }
            );
            this.downstream(4); // 发送心跳检查是否联网
        },
        beforeDestroy() {
            websocket.disconnect();
            cleanTimer(this);
        },
        methods: {
            entryRecord() {
                this.$router.push({
                    name: 'Awgels_Record',
                    params: {
                        deviceNo: this.deviceNo
                    }
                })
            },
            entryDevice() {
                this.$router.push({
                    name: 'Awgels_Device',
                    params: {
                        deviceNo: this.deviceNo
                    }
                })
            },
            downstream(e) {
                if (this.loading) {
                    return;
                }
                // 重置倒计时及定时器
                this.times = 30;
                cleanTimer(this);
                if (e != 4) {
                    this.loading = true;
                }
                // set_power_status set_timer query_status
                let _cmd = 'set_power_status';
                // standby make_O2 atomization
                let _status = "standby"; // 默认待机
                let _dsTime = 0;
                let _eventVal = false; // 默认关闭
                if (e == 1) {
                    _eventVal = this.zy_open;
                    _status = this.zy_open ? "standby" : "make_O2";
                } else if (e == 2) {
                    _eventVal = this.wh_open;
                    _status = this.wh_open ? "standby" : "atomization";
                } else if (e == 3) {
                    // 定时
                    _eventVal = this.ds_open;
                    this.mask_open = false;
                    this.dialog_open = false;
                    _cmd = "set_timer";
                    _dsTime = this.ds_open ? 0 : this.ds_time;
                    _status = this.zy_open ? 'make_O2' : 'atomization';
                } else {
                    _cmd = "query_status";
                }
                http.instance({
                    url: '/mqtt/send',
                    method: 'post',
                    data: {
                        cmd: _cmd,
                        status: _status,
                        dsTime: _dsTime,
                        deviceNo: this.deviceNo
                    }
                }).then(() => {
                    // 发送指令成功后记录操作之前事件
                    this.eventKey = e;
                    this.eventVal = _eventVal;
                    this.timer = setInterval(() => {
                        this.times--;
                        if (this.times === 0) {
                            this.loading = false;
                            cleanTimer(this);
                            alert(e == 4 ? '请检查设备是否已联网' : '操作失败，请稍候再试');
                        }
                    }, 1000);
                }).catch(err => {
                    if (this.loading) {
                        this.loading = false;
                        alert('操作失败，请稍候再试');
                    }
                    console.log('err: ' + JSON.stringify(err));
                });
            },
            entryStatistics() {
                this.$router.push({
                    name: 'Awgels_Statistics',
                    params: {
                        deviceNo: this.deviceNo
                    }
                })
            },
            closeDialog() {
                this.mask_open = false;
                this.dialog_open = false;
            },
            dsOperator(isAdd) {
                if (!isAdd && this.ds_time <= 10) {
                    return;
                }
                if (isAdd && this.ds_time >= 1500) {
                    return;
                }
                this.ds_time = isAdd ? this.ds_time + 10 : this.ds_time - 10;
            },
            dsControl() {
                if (this.ds_open) {
                    // 关闭定时
                    this.downstream(3);
                } else if (this.zy_open || this.wh_open) {
                    this.mask_open = true;
                    this.dialog_open = true;
                }
            }
        },
    };

    function showCtlResponse(res, vm) {
        cleanTimer(vm);
        vm.loading = false;
        vm.zy_open = res.zy_open;
        vm.wh_open = res.wh_open;
        vm.ds_open = res.ds_open;
        let _statusLabel = '待机中...';
        if (res.zy_open) {
            _statusLabel = '制氧中...';
        }
        if (res.wh_open) {
            _statusLabel = '雾化中...';
        }
        vm.statusLabel = _statusLabel;
        vm.dsLabel = res.ds_open ? res.ds_time + '分钟' : '定时';
    }

    function cleanTimer(vm) {
        try {
            clearInterval(vm.timer);
            vm.eventKey = 0; //重置
        } catch (e) {
            alert('cleanTimer err: ' + JSON.stringify(e));
        }
    }

    function showResponse(res, vm) {
        // 判断下发指令结果，清除操作定时器
        if ((vm.eventKey == 4)
            || (vm.eventKey == 1 && vm.eventVal != res.zy_open)
            || (vm.eventKey == 2 && vm.eventVal != res.wh_open)
            || (vm.eventKey == 3 && vm.eventVal != res.ds_open)) {
            cleanTimer(vm);
            vm.loading = false;
        }
        vm.zy_open = res.zy_open;
        vm.wh_open = res.wh_open;
        vm.ds_open = res.ds_open;
        let _statusLabel = '待机中...';
        let _oxyBlood = '--';
        let _flow = '0';
        if (res.zy_open) {
            _statusLabel = '制氧中...';
            _flow = res.flow;
            vm.oxyConcentration = res.oxyConcentration;
        }
        if (res.wh_open) {
            _statusLabel = '雾化中...';
            _flow = res.flow;
            vm.oxyConcentration = res.oxyConcentration;
        }
        if (res.oxyBlood && res.oxyBlood) {
            _oxyBlood = res.oxyBlood;
        }
        vm.statusLabel = _statusLabel;
        vm.oxyBlood = _oxyBlood;
        vm.flow = _flow;
        vm.cumulativeTime = res.cumulativeTime;
        vm.dsLabel = res.ds_open ? res.ds_time + '分钟' : '定时';
    }
</script>

<style scoped lang="scss">
    .yx_l_chart{
        float: left;
        margin-left: 5%;
        width: 50px;
        height: 50px;
        background-color: #F7F7F7;
        border-radius: 50%;
        text-align: center;
        line-height: 50px;
    }
    .yx_r_chart{
        float: right;
        margin-right: 5%;
        margin-top: -50px;
        width: 50px;
        height: 50px;
        background-color: #F7F7F7;
        border-radius: 50%;
        text-align: center;
        line-height: 50px;
    }
    .yj_chart{
        margin: 0px auto;
        width: 120px;
        height: 50px;
        background-color: #F7F7F7;
        border-radius: 50px;
        text-align: center;
        line-height: 50px;
    }
    .yx-chart{
        margin: 0px auto;
        width: 220px;
        height: 220px;
        background-color: #0066FF;
        border-radius: 50%;
        text-align: center;
    }
    .yx-chart span{
        color: #FFFFFF;
    }
    div ul{
        float: left;
        list-style: none;
        width: 30%
    }
    div ul li{
        text-align: center;
    }
    .tab-bottom{
        bottom: 0;
        width: 100%;
        position: fixed;
        border-top: none;
        background-color: #ECEFF1;
    }
    .y-line{
        float: left;
        width: 1px;
        height: 50px;
        background: darkgray;
    }
    .weui-tabbar__label{
        color:rgba(0, 0, 0, 0.9);
        font-size:16px;
        line-height:2.2;
    }
    .weui-tabbar:before{
        border-top:none;
    }
    .weui-tabbar__item.weui-bar__item_on .weui-tabbar__icon{
        color: #0066FF;
    }
    .weui-tabbar__item.weui-bar__item_on .weui-tabbar__label{
        color: #0066FF;
    }
    .weui-half-screen-dialog {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        padding: 0;
    }
    .weui-half-screen-dialog__hd{
        font-size: 16px;
        height: 3em;
    }
    .weui-half-screen-dialog__hd__side{ left: 8px }
    .weui-half-screen-dialog__title { color: rgba(0,0,0,.8); font-size: 16px; font-weight: normal; color: rgba(0, 0, 0, 0.5) }
</style>
