import SockJS from "sockjs-client";
import Stomp from "stompjs";
import store from "../store";

// websocket client
const websocket = {
    data() {
        return {
            stompClient: ''
        }
    },
    connect(url, dest, callback, dest2, callback2) {
        if (this.stompClient) {
            this.disconnect();
        }
        console.log('ws connect url：' + url);
        // 创建socket对象
        let socket = new SockJS(url);
        // 获取STOMP子协议的客户端对象
        this.stompClient = Stomp.over(socket);
        // 定义客户端认证信息
        let headers = {
            Authorization: store.state.token
        };
        // 调式信息
        this.stompClient.debug = null;
        /*this.stompClient.debug = function(msg) {
            console.log(msg);
        };*/
        // 客户端每20s发送心跳
        //this.stompClient.heartbeat.outgoing = 20000;
        // 客户端不从服务端接收心跳
        //this.stompClient.heartbeat.incoming = 0;
        // 向服务器发起websocket连接
        this.stompClient.connect(headers,() => {
            // 订阅服务端topic, 可订阅多个
            this.stompClient.subscribe(dest, (response) => {
                if (response.body) {
                    console.log('ws response: ' + response.body);
                }
                if (callback) {
                    callback(JSON.parse(response.body));
                }
            });
            this.stompClient.subscribe(dest2, (response) => {
                if (response.body) {
                    console.log('ws response: ' + response.body);
                }
                if (callback2) {
                    callback2(JSON.parse(response.body));
                }
            });
        }, (err) => {
            // 连接出现错误处理函数
            console.log('ws connect error: ' + JSON.stringify(err));
            // 2s后自动重连
            setTimeout(() => {
                this.connect(url, dest, callback, dest2, callback2);
            }, 2000);
        })
    },
    disconnect() {
        if (this.stompClient) {
            this.stompClient.disconnect();
            console.log('ws disconnect success...');
        }
    }
}

export default websocket; // 导出自定义创建websocket client对象
